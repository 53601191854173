import { Text, TextProps, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import shallow from "zustand/shallow";
import { useDashboardStore } from "../../stores/dashboard.store";

interface HeaderDasboardLinkProps extends TextProps {
  dashboardIndex: number;
}

const HeaderDasboardLink: React.FC<HeaderDasboardLinkProps> = ({
  children,
  dashboardIndex,
  ...rest
}) => {
  const { pathname } = useLocation();

  const [selectedDashboard, changeDashboard] = useDashboardStore(
    (state) => [state.selectedDashboard, state.changeDashboard],
    shallow
  );

  const isCurrent =
    pathname === "/dashboard" && selectedDashboard === dashboardIndex;

  const notCurrentLinkColor = useColorModeValue(
    "light.headerLinkColor",
    "dark.headerLinkColor"
  );

  return (
    <Link to="/dashboard">
      <Text
        onClick={() => changeDashboard(dashboardIndex)}
        aria-selected={isCurrent}
        color={notCurrentLinkColor}
        fontWeight="400"
        fontSize="16px"
        lineHeight="19px"
        _selected={{
          color: "global.primary",
          fontWeight: "bold",
        }}
        {...rest}
      >
        {children}
      </Text>
    </Link>
  );
};

export default HeaderDasboardLink;
