import { Flex, FlexProps, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { addAlpha } from "../../utils/kpis";

interface KPIBoxProps extends FlexProps {
  iconColor: string;
  icon: React.ReactNode;
  value: string;
  title: string;
}

const KPIBox: React.FC<KPIBoxProps> = ({
  iconColor,
  icon,
  value,
  title,
  ...rest
}) => {
  const cardBackground = useColorModeValue(
    "light.cardsBackground",
    "dark.cardsBackground"
  );

  const cardsBorderColor = useColorModeValue(
    "light.cardsBorderColor",
    "dark.cardsBorderColor"
  );

  const infoBoxTitleColor = useColorModeValue(
    "light.infoBoxTitleColor",
    "dark.infoBoxTitleColor"
  );
  // const infoBoxDotsColor = useColorModeValue(
  //   "light.infoBoxDotsColor",
  //   "dark.infoBoxDotsColor"
  // );

  const iconBackgroundColor = addAlpha(iconColor, 0.1);

  return (
    <Flex
      w="full"
      borderRadius="8px"
      align="center"
      p="24px"
      borderColor={cardsBorderColor}
      borderWidth="1px"
      bgColor={cardBackground}
      {...rest}
    >
      <Flex
        boxSize="56px"
        justify="center"
        align="center"
        borderRadius="100%"
        bgColor={iconBackgroundColor}
      >
        {icon}
      </Flex>
      <Flex flex="1" pl="16px" flexDir="column">
        <Text fontWeight="semibold" fontSize="24px" lineHeight="29px">
          {value}
        </Text>
        <Text
          fontSize="16px"
          fontWeight="medium"
          lineHeight="19px"
          color={infoBoxTitleColor}
        >
          {title}
        </Text>
      </Flex>
    </Flex>
  );
};

export default KPIBox;
