import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

const SmallButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button
      h="33px"
      bgColor="global.primary"
      variant="solid"
      borderRadius="4px"
      color="white"
      fontWeight="600"
      lineHeight="17px"
      fontSize="14px"
      _hover={{}}
      _active={{}}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default SmallButton;
