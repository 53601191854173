import { ColorMode } from "@chakra-ui/react";
import { GoogleChartOptions } from "react-google-charts";

interface SankeyChartsConfigProps {
  theme: ColorMode;
  labels: string[];
  chartId: string;
}

enum ChartTheme {
  LIGHT_TEXT_COLOR = "#1E3047",
  DARK_TEXT_COLOR = "#D6E8FF",
}

var colors = ['#a6cee3', '#b2df8a', '#fb9a99', '#fdbf6f',
'#cab2d6', '#ffff99', '#1f78b4', '#33a02c'];


export const sankeyChartsConfig = ({
  theme,
  labels,
  chartId,
}: SankeyChartsConfigProps) =>  ( 
 {
  sankey: {
    link: {
      colorMode: 'gradient'
      
    },
    node: {
      colorMode: 'unique',
      interactivity: true,
      label: {
        color: 
          theme === "light"
          ? ChartTheme.LIGHT_TEXT_COLOR
          : ChartTheme.DARK_TEXT_COLOR,
      }
    }
  },
 }
);
