import React, { useState, useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import RegularButton from "../components/buttons/RegularButton";
import CustomTable from "../components/tables/CustomTable";
import PageTitle from "../components/global/PageTitle";
import NormalLayout from "../components/layouts/NormalLayout";
import ManageUserTableItem from "../components/tables/ManageUserTableItem";
import CreateUserModal from "../components/modals/CreateUserModal";
import EditUserModal from "../components/modals/EditUserModal";
import { User } from "../utils/api-data-types";
import useApi from "../hooks/useApi";

const ManageUsers: React.FC = () => {
  const { fetchUsers } = useApi();
  const [isOpen, setIsOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<User | null>(null);
  const [userError, setUserError] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    setUserError(!!(users as any)?.error);
  }, [users]);

  useEffect(() => {
    if (userToEdit) setIsOpen(true);
  }, [userToEdit]);

  const onClose = () => {
    setUserToEdit(null);
    setIsOpen(false);
  };
  const onOpen = () => setIsOpen(true);

  useEffect(() => {
    (async () => {
      const usersList = await fetchUsers();
      setUsers(usersList);
    })();
  }, [fetchUsers]);

  const refreshUsers = async () => {
    const usersList = await fetchUsers();
    setUsers(usersList);
  };

  return (
    <>
      <NormalLayout>
        <Flex w="full" flexDir="column" px="24px" pb="28px">
          <Flex
            align="center"
            mt="16px"
            justify="space-between"
            w="full"
            as="header"
          >
            <PageTitle>Manage Users</PageTitle>
            {!userError ? (
              <RegularButton flex="1" maxW="220px" onClick={onOpen}>
                Add New User
              </RegularButton>
            ) : null}
          </Flex>
          {!userError ? (
            <>
              {users.length ? (
                <CustomTable
                  headers={[
                    "Name",
                    "Email",
                    "User role",
                    "Last access",
                    "Password",
                    "Actions",
                  ]}
                >
                  {users?.map((user: User) => (
                    <ManageUserTableItem
                      edit={() => setUserToEdit(user)}
                      refreshUsers={refreshUsers}
                      key={user.email}
                      user={user}
                    />
                  ))}
                </CustomTable>
              ) : (
                <p>Loading...</p>
              )}
            </>
          ) : (
            <p>No users found or invalid permissions</p>
          )}
        </Flex>
      </NormalLayout>

      {userToEdit ? (
        <EditUserModal
          isOpen={isOpen}
          onClose={onClose}
          user={userToEdit}
          refreshUsers={refreshUsers}
        />
      ) : (
        <CreateUserModal
          isOpen={isOpen}
          onClose={onClose}
          refreshUsers={refreshUsers}
        />
      )}
    </>
  );
};

export default ManageUsers;
