import { Heading, HeadingProps } from "@chakra-ui/react";
import React from "react";

const PageTitle: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading as="h1" fontWeight="medium" fontSize="32px" {...rest}>
      {children}
    </Heading>
  );
};

export default PageTitle;
