import {
  HStack,
  Td,
  Tr,
  useColorModeValue,
  Box,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";
import { formatDateDisplay } from "../../utils/formatting";
import { User, UserRole } from "../../utils/api-data-types";
import useApi from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";

type Props = {
  user: User;
  refreshUsers: () => Promise<void>;
  edit?: () => void;
};

const ManageUserTableItem: React.FC<Props> = ({ user, refreshUsers, edit }) => {
  const { deleteUser } = useApi();
  const { resetPassword } = useAuth();
  const toast = useToast();
  const { last_login_date: lastAccess, name, email, role } = user;

  const lastAccessDate = formatDateDisplay(lastAccess) || "N/A";

  const customTableItemTextColor = useColorModeValue(
    "light.customTableItemTextColor",
    "dark.customTableItemTextColor"
  );
  const boxLinkStyles = {
    bg: "none",
    border: "none",
    outline: "none",
    color: "global.primary",
    _hover: { textDecoration: "underline", cursor: "pointer" },
    _focus: { boxShadow: "none" },
    _active: { outline: "none" },
  };

  const changePassword = async () => {
    const { error } = await resetPassword(user.email);
    if (error) {
      toast({
        position: "top",
        title: "Error",
        description: error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    toast({
      position: "top",
      title: "Success",
      description: "Password reset email sent.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    await refreshUsers();
  };

  const deleteRowUser = async () => {
    const { error } = await deleteUser(user.email);
    if (error) {
      toast({
        position: "top",
        title: "Error",
        description: error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    toast({
      position: "top",
      title: "Success",
      description: "User deleteed.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    await refreshUsers();
  };

  return (
    <Tr h="64px" fontSize="16px" color={customTableItemTextColor}>
      <Td>{name}</Td>
      <Td>{email}</Td>
      <Td>{UserRole[role]}</Td>
      <Td>{lastAccessDate}</Td>
      <Td>
        <Box
          {...boxLinkStyles}
          as="button"
          onClick={changePassword}
          p={0}
          m={0}
        >
          <div id="pseudo-link">
            {user.hashed_password ? "Reset Password" : "Setup Password"}
          </div>
        </Box>
      </Td>
      <Td>
        <HStack spacing="24px">
          <EditButton onClickAction={edit} />
          <DeleteButton onClickAction={deleteRowUser} />
        </HStack>
      </Td>
    </Tr>
  );
};

export default ManageUserTableItem;
