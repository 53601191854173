import { Flex, HStack, IconButton, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import {
  CgPushChevronLeft,
  CgPushChevronRight,
  CgChevronLeft,
  CgChevronRight,
} from "react-icons/cg";
import shallow from "zustand/shallow";
import { exampleStructure } from "../../dashboard-structures/example-structure";
import { useDashboardStore } from "../../stores/dashboard.store";

import PaginationButton from "./PaginationButton";

const PaginationTab: React.FC = () => {
  const [selectedDashboardPage, changeDashboardPage, selectedDashboard] =
    useDashboardStore(
      (state) => [
        state.selectedDashboardPage,
        state.changeDashboardPage,
        state.selectedDashboard,
      ],
      shallow
    );

  const cardsBackgroundColor = useColorModeValue(
    "light.cardsBackground",
    "dark.cardsBackground"
  );

  const paginationTabActionButtonColor = useColorModeValue(
    "light.paginationTabActionButtonColor",
    "dark.paginationTabActionButtonColor"
  );

  return (
    <Flex
      pos="fixed"
      zIndex="100"
      bottom="0"
      w="full"
      py="12px"
      bgColor={cardsBackgroundColor}
      justify="center"
      align="center"
    >
      <HStack spacing={["8px", "20px"]}>
        <IconButton
          fontSize="24px"
          color={paginationTabActionButtonColor}
          aria-label="first"
          icon={<CgPushChevronLeft />}
          disabled={selectedDashboardPage === 0}
          onClick={() => changeDashboardPage(0)}
        />
        <IconButton
          aria-label="left"
          fontSize="24px"
          color={paginationTabActionButtonColor}
          icon={<CgChevronLeft />}
          disabled={selectedDashboardPage === 0}
          onClick={() => changeDashboardPage(selectedDashboardPage - 1)}
        />
        <HStack spacing="20px">
          {exampleStructure[selectedDashboard].pages.map((d, i) => (
            <PaginationButton
              key={i}
              onClick={() => changeDashboardPage(i)}
              aria-selected={selectedDashboardPage === i}
            >
              {i + 1}
            </PaginationButton>
          ))}
        </HStack>
        <IconButton
          aria-label="right"
          fontSize="24px"
          color={paginationTabActionButtonColor}
          icon={<CgChevronRight />}
          onClick={() => changeDashboardPage(selectedDashboardPage + 1)}
          disabled={
            selectedDashboardPage ===
            exampleStructure[selectedDashboard].pages.length - 1
          }
        />
        <IconButton
          aria-label="last"
          fontSize="24px"
          color={paginationTabActionButtonColor}
          icon={<CgPushChevronRight />}
          disabled={
            selectedDashboardPage ===
            exampleStructure[selectedDashboard].pages.length - 1
          }
          onClick={() =>
            changeDashboardPage(
              exampleStructure[selectedDashboard].pages.length - 1
            )
          }
        />
      </HStack>
    </Flex>
  );
};

export default PaginationTab;
