import { Text } from "@chakra-ui/react";
import moment from "moment";
import { createContext, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import shallow from "zustand/shallow";
import { useDashboardStore } from "../stores/dashboard.store";

const DashboardContext = createContext({});

const DashboardProvider = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [loadDashboardData, dateRange] = useDashboardStore(
    (s) => [s.loadDashboardData, s.dateRange],
    shallow
  );

  useEffect(() => {
    const startDateString = moment(dateRange[0]).format("YYYYMMDD");
    const endDateString = moment(dateRange[1]).format("YYYYMMDD");

    loadDashboardData({
      process: "between",
      filter: "DateId",
      value: [startDateString, endDateString].join(","),
    })
      .then(() => setIsLoading(false))
      .catch((e) => console.error(e));
  });

  return (
    <DashboardContext.Provider value={{}}>
      {isLoading ? <Text>Loading...</Text> : <Outlet />}
    </DashboardContext.Provider>
  );
};

export { DashboardContext, DashboardProvider };
