import { KPIData } from "../@types/interfaces/app/dashboard-structure";

export const loadKPIData = (
  data: { [key: string]: any[] },
  kpiData: KPIData
) => {
  const apiData = data[kpiData.KPIKey];
  return apiData[0][Object.keys(apiData[0])[0]].toString();
};

export const addAlpha = (hex: string, opacity: number) => {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return hex + _opacity.toString(16).toUpperCase();
};
