import {
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { RiCalendarEventLine } from "react-icons/ri";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import moment from "moment";

interface DateInputProps {
  selected: Date;
  onChange: (date: Date) => void;
}

const DateInputButton = forwardRef<
  HTMLButtonElement,
  { value?: any; onClick?: any }
>(({ value, onClick }, ref) => {
  const cardBackground = useColorModeValue(
    "light.cardsBackground",
    "dark.cardsBackground"
  );

  const cardsBorderColor = useColorModeValue(
    "light.cardsBorderColor",
    "dark.cardsBorderColor"
  );

  const filterIconColor = useColorModeValue(
    "light.filterIconColor",
    "dark.filterIconColor"
  );

  return (
    <Button
      h="48px"
      fontSize="14px"
      fontWeight="medium"
      bgColor={cardBackground}
      borderColor={cardsBorderColor}
      borderWidth="1px"
      color={filterIconColor}
      _hover={{}}
      _active={{}}
      onClick={onClick}
      ref={ref}
      leftIcon={
        <Icon
          fontSize="21px"
          as={RiCalendarEventLine}
          color={filterIconColor}
        />
      }
    >
      {value}
    </Button>
  );
});

interface CustomHeaderProps {
  decreaseMonth: any;
  increaseMonth: any;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  date: Date;
}

const CustomHeader: React.FC<CustomHeaderProps> = ({
  date,
  increaseMonth,
  decreaseMonth,
}) => {
  const menuCardBackground = useColorModeValue(
    "light.menuCardBackground",
    "dark.menuCardBackground"
  );
  const filterTextColor = useColorModeValue(
    "light.filterTextColor",
    "dark.filterTextColor"
  );
  return (
    <Flex
      w="full"
      h="20px"
      justify="space-between"
      align="center"
      fontFamily="Montserrat"
      color={filterTextColor}
      bgColor={menuCardBackground}
      mb="16px"
    >
      <IconButton
        onClick={decreaseMonth}
        aria-label=""
        icon={<RiArrowLeftSLine fontSize="24px" />}
      />
      <Text fontSize="16px" fontWeight="bold">
        {moment(date).format("MMM yyyy")}
      </Text>
      <IconButton
        onClick={increaseMonth}
        aria-label=""
        icon={<RiArrowRightSLine fontSize="24px" />}
      />
    </Flex>
  );
};

interface CustomDayProps {
  day: any;
  date: Date;
}

const CustomDay: React.FC<CustomDayProps> = ({ day, date }) => {
  return (
    <Text fontSize="13px" fontFamily="Montserrat">
      {day}
    </Text>
  );
};

const DateInput: React.FC<DateInputProps> = ({ selected, onChange }) => {
  const { colorMode } = useColorMode();

  return (
    <DatePicker
      selected={selected}
      className={
        colorMode === "dark" ? "date-picker-dark" : "date-picker-light"
      }
      onChange={(date: Date) => onChange(date)}
      customInput={<DateInputButton />}
      calendarClassName={
        colorMode === "dark"
          ? "date-picker-calendar-dark"
          : "date-picker-calendar-light"
      }
      renderCustomHeader={(props: any) => <CustomHeader {...props} />}
      renderDayContents={(day: any, date: Date) => (
        <CustomDay date={date} day={day} />
      )}
    />
  );
};

export default DateInput;
