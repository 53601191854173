import { HStack, Td, Tr, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { ALLOW_NEW_ROLE_CREATION } from "../../pages/UserRoles";
import { User } from "../../utils/api-data-types";
import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";

type Props = {
  role: "Admin" | "View Only";
  users: User[];
};

const UserRoleTableItem: React.FC<Props> = ({ role, users }) => {
  const customTableItemTextColor = useColorModeValue(
    "light.customTableItemTextColor",
    "dark.customTableItemTextColor"
  );

  return (
    <Tr h="64px" fontSize="16px" color={customTableItemTextColor}>
      <Td>{role}</Td>
      {/* <Td>April 4, 2022 at 11:20 AM</Td> */}
      <Td>{users.length}</Td>
      {ALLOW_NEW_ROLE_CREATION && (
        <Td>
          <HStack spacing="24px">
            <EditButton />
            <DeleteButton />
          </HStack>
        </Td>
      )}
    </Tr>
  );
};

export default UserRoleTableItem;
