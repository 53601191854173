import { ChakraProvider, GlobalStyle } from "@chakra-ui/react";
import { theme } from "./styles/theme";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ManageUsers from "./pages/ManageUsers";
import UserRoles from "./pages/UserRoles";
import ExpandedChart from "./pages/ExpandedChart";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/date-picker/date-picker.css";
import "./styles/overrides.css";
import Home from "./pages/Home";
import AuthReturn from "./pages/AuthReturn";
import LogoutReturn from "./pages/LogoutReturn";
import { DashboardProvider } from "./providers/DashboardProvider";
import { useContext } from "react";
import { AuthContext, AuthProvider } from "./providers/AuthProvider";

const AppRoutes = () => {
  const { userIsAdmin } = useContext(AuthContext);
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicOutlet />}>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/auth/return" element={<AuthReturn />} />
        </Route>
        <Route element={<AuthOutlet />}>
          <Route path="/logout/return" element={<LogoutReturn />} />
          <Route element={<DashboardProvider />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/manage-users"
              element={userIsAdmin ? <ManageUsers /> : <Home />}
            />
            <Route
              path="/user-roles"
              element={userIsAdmin ? <UserRoles /> : <Home />}
            />
            <Route path="/expanded" element={<ExpandedChart />} />
          </Route>
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <GlobalStyle />
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </ChakraProvider>
  );
};

const AuthOutlet = () => {
  const { authenticated } = useContext(AuthContext);
  return authenticated ? <Outlet /> : <Navigate to="/login" />;
};

const PublicOutlet = () => {
  const { authenticated } = useContext(AuthContext);
  return authenticated ? <Navigate to="/dashboard" /> : <Outlet />;
};
