import { Flex, Grid, Icon, Text, VStack } from "@chakra-ui/react";
import React from "react";
import ChartBox from "../components/charts/ChartBox";
import KPIBox from "../components/charts/KPIBox";
import CategoriesFilter from "../components/filters/CategoriesFilter/CategoriesFilter";
import ExportMenu from "../components/global/ExportMenu";
import PageTitle from "../components/global/PageTitle";
import PaginationLayout from "../components/layouts/PaginationLayout";
import { useDashboardStore } from "../stores/dashboard.store";
import shallow from "zustand/shallow";
import DateRangeFilter from "../components/filters/DateRangeFilter";
import { exampleStructure } from "../dashboard-structures/example-structure";
import { exportAllChartsToPpt, exportCsv } from "../utils/exports";
import { convertChartData } from "../utils/charts";
import { loadKPIData } from "../utils/kpis";

const Dashboard: React.FC = () => {
  const [dashboardsData, selectedDashboardPage, selectedDashboard] =
    useDashboardStore(
      (state) => [
        state.dashboardsData,
        state.selectedDashboardPage,
        state.selectedDashboard,
      ],
      shallow
    );

  const dashboard = exampleStructure[selectedDashboard];
  const KPIs = dashboardsData
    ? dashboard.pages[selectedDashboardPage].KPIs
    : [];
  const charts = dashboardsData
    ? dashboard.pages[selectedDashboardPage].charts
    : [];

  const templateArea = dashboardsData
    ? dashboard.pages[selectedDashboardPage].templateArea
    : undefined;

  return (
    <>
      <PaginationLayout>
        <Flex pb="24px" flex="1" w="full" flexDir="column" px="24px">
          {dashboardsData ? (
            <>
              <Flex
                as="header"
                w="full"
                mt="16px"
                gap="16px"
                flexDirection={["column", null, null, null, "row"]}
              >
                <PageTitle>{dashboard.name}</PageTitle>
                <Flex
                  zIndex={"banner"}
                  flex="1"
                  gap="16px"
                  wrap="wrap"
                  justify={["flex-start", null, null, null, "flex-end"]}
                >
                  <DateRangeFilter />
                  <CategoriesFilter />
                  <ExportMenu
                   // exportPpt={() =>
                   //   exportAllChartsToPpt(charts.flatMap((d) => d.chartId))
                   // }
                    exportCsv={() => {
                      for (const chart of charts) {
                        exportCsv(
                          chart.chartId,
                          convertChartData(dashboardsData, chart)
                        );
                      }
                    }}
                  />
                </Flex>
              </Flex>
              <Grid
                w="full"
                mt="28px"
                flex="1"
                gap="12px"
                templateAreas={templateArea}
                gridAutoColumns={"minmax(0, 1fr)"}
              >
                {KPIs &&
                  KPIs.map((d) => (
                    <KPIBox
                      key={d.name}
                      gridArea={d.KPIKey}
                      value={loadKPIData(dashboardsData, d)}
                      title={d.name}
                      iconColor={d.color}
                      icon={
                        <Icon
                          color={d.color}
                          as={d.icon as any}
                          fontSize="23px"
                        />
                      }
                    />
                  ))}

                {charts.map((d, i) => (
                  <ChartBox
                    key={d.chartId}
                    gridArea={d.chartId}
                    chartData={d}
                    minWidth="0"
                    minHeight="440px"
                  />
                ))}
              </Grid>
            </>
          ) : (
            <Text>Loading...</Text>
          )}
        </Flex>
      </PaginationLayout>
    </>
  );
};

export default Dashboard;
