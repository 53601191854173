import { Flex } from "@chakra-ui/react";
import React from "react";
import Header from "../header/Header";
import PaginationTab from "../global/PaginationTab";
import ComponentProps from "../../@types/interfaces/app/component-props.interface";

const PaginationLayout: React.FC<ComponentProps> = ({ children }) => {
  return (
    <Flex
      pos="relative"
      flexDir="column"
      w="full"
      h="100vh"
      pb="64px"
      overflowX="auto"
    >
      <Header />
      {children}
      <PaginationTab />
    </Flex>
  );
};

export default PaginationLayout;
