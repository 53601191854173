import { Button } from "@chakra-ui/react";
import React from "react";
import { FiEdit } from "react-icons/fi";

type Props = {
  onClickAction?: () => void;
};

const EditButton: React.FC<Props> = ({ onClickAction }) => {
  return (
    <Button
      onClick={onClickAction}
      _hover={{ textDecor: "underline" }}
      px="0"
      fontSize="16px"
      fontWeight="medium"
      leftIcon={<FiEdit fontSize="19px" />}
      lineHeight="19px"
    >
      Edit
    </Button>
  );
};

export default EditButton;
