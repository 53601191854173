import React from "react";
import {
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  TableContainerProps,
  useColorModeValue,
} from "@chakra-ui/react";

interface CustomTableProps extends TableContainerProps {
  headers: string[];
}

const CustomTable: React.FC<CustomTableProps> = ({ headers, children }) => {
  const customTableBackground = useColorModeValue(
    "light.customTableBackground",
    "dark.customTableBackground"
  );

  const customTableRowScheme = useColorModeValue(
    "light.customTableRowScheme",
    "dark.customTableRowScheme"
  );

  const customTableHeaderBackground = useColorModeValue(
    "light.customTableHeaderBackground",
    "dark.customTableHeaderBackground"
  );

  const customTableHeaderBorderBottom = useColorModeValue(
    "light.customTableHeaderBorderBottom",
    "dark.customTableHeaderBorderBottom"
  );

  return (
    <TableContainer mt="28px" w="full">
      <Table
        bgColor={customTableBackground}
        colorScheme={customTableRowScheme}
        variant="striped"
      >
        <Thead bgColor={customTableHeaderBackground}>
          <Tr h="50px">
            {headers.map((header) => (
              <Th
                color="#8998AC"
                borderColor={customTableHeaderBorderBottom}
                fontSize="14px"
                fontWeight="semibold"
                textTransform="capitalize"
                key={header}
              >
                {header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
