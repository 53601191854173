import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

const PaginationButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button
      fontWeight="medium"
      borderRadius="100px"
      fontSize="14px"
      p="0"
      _selected={{
        bgColor: "global.primary",
        color: "white",
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default PaginationButton;
