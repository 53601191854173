import { Flex, Image, useColorMode } from "@chakra-ui/react";
import React from "react";
import GridContainer from "../global/GridContainer";
import logoDark from "../../assets/images/logos/secondary-dark-mode.svg";
import logoLight from "../../assets/images/logos/secondary-light-mode.svg";
import { Link } from "react-router-dom";
import HeaderProfileBox from "./HeaderProfileBox";
import { exampleStructure } from "../../dashboard-structures/example-structure";
import HeaderDasboardLink from "./HeaderDasboardLink";

const Header: React.FC = () => {
  const { colorMode } = useColorMode();

  return (
    <GridContainer align="center" mt="2" wrap="wrap">
      <Link to="/dashboard">
        <Image
          src={colorMode === "dark" ? logoDark : logoLight}
          w="125px"
          alt="logo"
          mr="32px"
        />
      </Link>

      <Flex
        gap={["20px", null, "40px"]}
        flex={["1 1 100%", null, null, "1"]}
        wrap="wrap"
        order={[10, null, null, "initial"]}
        my={["16px", null, null, 0]}
      >
        {exampleStructure.map((d, i) => (
          <HeaderDasboardLink key={i} dashboardIndex={i}>
            {d.name}
          </HeaderDasboardLink>
        ))}
      </Flex>

      <Flex gap="16px" wrap="nowrap" align="center" ml="auto">
        <HeaderProfileBox />
      </Flex>
    </GridContainer>
  );
};

export default Header;
