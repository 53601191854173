import {
  Flex,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
  Box,
  Input,
  Link,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import logoDark from "../assets/images/logos/primary-dark-mode.svg";
import logoLight from "../assets/images/logos/primary-light-mode.svg";
import FilledButton from "../components/buttons/FilledButton";
import { AuthContext } from "../providers/AuthProvider";
import { checkValidEmail } from "../utils/formatting";
import useAuth from "../hooks/useAuth";
import useConfig from "../hooks/useConfig";

const Login: React.FC = () => {
  const { ssoAuthUrl } = useConfig();
  const { loginWithPassword, resetPassword, setNewPassword } = useAuth();
  const toast = useToast();
  const { signIn } = useContext(AuthContext);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [canLogin, setCanLogin] = useState<boolean>(false);
  const [view, setView] = useState<"login" | "forgot" | "change-pw">("login");

  useEffect(() => {
    const isValidEmail = checkValidEmail(email);
    const isValidPassword = checkValidPassword(password);
    setCanLogin(isValidEmail && isValidPassword);
  }, [email, password]);

  useEffect(() => setPassword(""), [view]);

  const { colorMode } = useColorMode();
  const cardBackground = useColorModeValue(
    "light.cardsBackground",
    "dark.cardsBackground"
  );

  const checkValidPassword = (password: string) => {
    if (!password) return false;
    return password.length > 7;
  };

  const resetPage = () => {
    setView("login");
    setEmail("");
    setPassword("");
  };

  const handleSSOLogin = () => window.open(ssoAuthUrl, "_self");
  const handlePasswordLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    const { base64Token, user, error } = await loginWithPassword(
      email,
      password
    );

    if (error || !base64Token) {
      toast({
        position: "top",
        title: "An error occurred.",
        description: error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (user?.require_pw_reset) {
      toast({
        position: "top",
        title: "Update password.",
        description: "Please set a new password.",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      setView("change-pw");
      return;
    }

    resetPage();
    signIn(base64Token);
  };

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();

    const { error } = await resetPassword(email);

    if (error) {
      toast({
        position: "top",
        title: "An error occurred.",
        description: error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    toast({
      position: "top",
      title: "Password reset email sent.",
      description: "Please check your email.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    resetPage();
  };

  const handleSetNewPassword = async (e: React.FormEvent) => {
    e.preventDefault();

    const { error } = await setNewPassword(email, password);

    if (error) {
      toast({
        position: "top",
        title: "An error occurred.",
        description: error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    toast({
      position: "top",
      title: "Password set.",
      description: "Please login with new credentials.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    resetPage();
  };

  if (view === "forgot") {
    return (
      <Flex justify="center" align="center" w="100vw" h="100vh">
        <Flex
          borderRadius="6px"
          w="full"
          maxW="455px"
          boxShadow="0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)"
          p={["24px", "48px"]}
          bgColor={cardBackground}
          align="center"
          flexDir="column"
        >
          <Text
            mb="16px"
            fontFamily="roboto"
            fontSize="24px"
            lineHeight="24px"
            fontWeight="400"
            letterSpacing="0.18px"
          >
            Reset Password
          </Text>
          <Box mt="24px" w="full">
            <form onSubmit={handlePasswordReset}>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                placeholder="Email"
                mb="16px"
                value={email}
              />
              <FilledButton
                disabled={!checkValidEmail(email)}
                type="submit"
                w="full"
                backgroundColor="blue.700"
              >
                Reset Password
              </FilledButton>
            </form>
          </Box>
          <Box onClick={() => setView("login")} as={Link} mt="12px">
            Back to Login
          </Box>
        </Flex>
      </Flex>
    );
  } else if (view === "change-pw") {
    return (
      <Flex justify="center" align="center" w="100vw" h="100vh">
        <Flex
          borderRadius="6px"
          w="full"
          maxW="455px"
          boxShadow="0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)"
          p={["24px", "48px"]}
          bgColor={cardBackground}
          align="center"
          flexDir="column"
        >
          <Text
            mb="16px"
            fontFamily="roboto"
            fontSize="24px"
            lineHeight="24px"
            fontWeight="400"
            letterSpacing="0.18px"
          >
            Set New Password
          </Text>
          <Box mt="24px" w="full">
            <form onSubmit={handleSetNewPassword}>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Password"
                mb="16px"
                value={password}
              />
              <Input
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                placeholder="Confirm Password"
                mb="16px"
                value={confirmPassword}
              />
              <FilledButton
                disabled={
                  !checkValidPassword(password) || password !== confirmPassword
                }
                type="submit"
                w="full"
                backgroundColor="blue.700"
              >
                Set Password
              </FilledButton>
            </form>
          </Box>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex justify="center" align="center" w="100vw" h="100vh">
      <Flex
        borderRadius="6px"
        w="full"
        maxW="455px"
        boxShadow="0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)"
        p={["24px", "48px"]}
        bgColor={cardBackground}
        align="center"
        flexDir="column"
      >
        <Image
          src={colorMode === "light" ? logoLight : logoDark}
          alt="logo"
          w="125px"
        />
        <Text
          fontSize="14px"
          lineHeight="20px"
          letterSpacing="0.25px"
          mt="24px"
          mb="12px"
        >
          Log in to continue to The Dashboard.
        </Text>
        <Box mt="24px" w="full">
          <form onSubmit={handlePasswordLogin}>
            <Input
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              placeholder="Email"
              mb="16px"
              value={email}
            />
            <Input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
              mb="24px"
              value={password}
            />
            <FilledButton
              disabled={!canLogin}
              type="submit"
              w="full"
              backgroundColor="blue.700"
            >
              Login with Password
            </FilledButton>
          </form>
          <Flex paddingY={2} justify="center">
            or
          </Flex>
          <FilledButton w="full" onClick={handleSSOLogin}>
            Continue with SSO
          </FilledButton>
        </Box>
        <Box onClick={() => setView("forgot")} as={Link} mt="12px">
          Forgot Password
        </Box>
      </Flex>
    </Flex>
  );
};

export default Login;
