import { Select, SelectProps } from "@chakra-ui/react";
import React from "react";

const SmallSelectInput: React.FC<SelectProps> = ({ children, ...rest }) => {
  return (
    <Select
      size="sm"
      borderRadius="4px"
      focusBorderColor="global.primary"
      h="33px"
      {...rest}
    >
      {children}
    </Select>
  );
};

export default SmallSelectInput;
