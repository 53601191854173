import { useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";

const useConfig = () => {
  const { accessToken } = useContext(AuthContext);

  const ssoProxyUrl = process.env.REACT_APP_CMG_SSO_PROXY_URL;

  const apiUrl = process.env.REACT_APP_API_HOST;
  const ssoAuthUrl = `${ssoProxyUrl}/auth`;
  const ssoLogoutUrl = `${ssoProxyUrl}/logout`;
  const ssoRefreshTokenUrl = `${ssoProxyUrl}/auth/refresh`;
  // Set the Max Content Length to a specific number to flag
  // that we have attempted to refresh the axios token before.
  const ssoRetryLimitFlag = Number(process.env.REACT_APP_RETRY_LIMIT_FLAG);
  const ssoAccessToken = accessToken || localStorage.getItem("accessToken");

  return {
    apiUrl,
    ssoAuthUrl,
    ssoLogoutUrl,
    ssoRefreshTokenUrl,
    ssoRetryLimitFlag,
    ssoAccessToken,
  };
};

export default useConfig;
