import { Button } from "@chakra-ui/react";
import { BiTrash } from "react-icons/bi";

type Props = {
  onClickAction?: () => void;
};

const DeleteButton: React.FC<Props> = ({ onClickAction }) => {
  return (
    <Button
      onClick={onClickAction}
      _hover={{ textDecor: "underline" }}
      px="0"
      color="#F83A51"
      fontSize="16px"
      fontWeight="medium"
      lineHeight="19px"
      leftIcon={<BiTrash fontSize="19px" />}
    >
      Delete
    </Button>
  );
};

export default DeleteButton;
