import {
  Flex,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import logoDark from "../assets/images/logos/primary-dark-mode.svg";
import logoLight from "../assets/images/logos/primary-light-mode.svg";
import { AuthContext } from "../providers/AuthProvider";

const AuthReturn: React.FC = () => {
  const { colorMode } = useColorMode();

  const cardBackground = useColorModeValue(
    "light.cardsBackground",
    "dark.cardsBackground"
  );

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();

  const { signIn } = useContext(AuthContext);

  useEffect(() => {
    // const email = query.get("email");
    const token = query.get("token");
    if (token) {
      localStorage.setItem("authToken", token);
      signIn(token);
    }
  });

  return (
    <Flex justify="center" align="center" w="100vw" h="100vh">
      <Flex
        borderRadius="6px"
        w="full"
        maxW="455px"
        boxShadow="0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)"
        p="48px"
        bgColor={cardBackground}
        align="center"
        flexDir="column"
      >
        <Image
          src={colorMode === "light" ? logoLight : logoDark}
          alt="logo"
          w="125px"
        />
        <Text
          fontSize="14px"
          lineHeight="20px"
          letterSpacing="0.25px"
          mt="24px"
          mb="12px"
        >
          Logging you in...
        </Text>
      </Flex>
    </Flex>
  );
};

export default AuthReturn;
