import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  VStack,
  useColorModeValue,
  Flex,
  Box,
  useToast,
} from "@chakra-ui/react";
import InputLabel from "../inputs/InputLabel";
import SmallInputText from "../inputs/SmallInputText";
import SmallButton from "../buttons/SmallButton";
import SmallSelectInput from "../inputs/SmallSelectInput";
import { checkValidEmail } from "../../utils/formatting";
import { User } from "../../utils/api-data-types";
import useApi from "../../hooks/useApi";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  refreshUsers: () => Promise<void>;
  user: User;
};

const EditUserModal: React.FC<Props> = ({
  isOpen,
  onClose,
  refreshUsers,
  user,
}) => {
  const toast = useToast();
  const { updateUser } = useApi();

  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(user.email);
  const [name, setName] = useState<string>(user.name);
  const [role, setRole] = useState<"VIEW_ONLY" | "ADMIN">(user.role);
  const [updatedUser, setUpdatedUser] = useState<User>(user);

  useEffect(() => {
    const isValidEmail = email && checkValidEmail(email);

    const checkCanSubmit = () => {
      const updatedUserString = JSON.stringify(updatedUser);
      const userString = JSON.stringify(user);
      const changesMade = updatedUserString !== userString;
      const canSubmit = changesMade && isValidEmail && name && role;
      setCanSubmit(!!canSubmit);
    };

    checkCanSubmit();
  }, [email, name, role, updatedUser, user]);

  useEffect(() => {
    setUpdatedUser((prev) => {
      return { ...prev, email, name, role };
    });
  }, [email, name, role]);

  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const modalBackgroundColor = useColorModeValue(
    "white",
    "dark.modalBackgroundColor"
  );

  const noBackgroundButtonTextColor = useColorModeValue(
    "light.noBackgroundButtonTextColor",
    "dark.noBackgroundButtonTextColor"
  );

  const modalHeaderBorderColor = useColorModeValue(
    "light.modalHeaderBorderColor",
    "dark.modalHeaderBorderColor"
  );

  const handleEditUser = async () => {
    const updatedUser = { email, name, role } as User;
    const { error } = await updateUser(user as User, updatedUser);
    if (error) {
      toast({
        position: "top",
        title: "Error",
        description: error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    toast({
      position: "top",
      title: "Success",
      description: "User created.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    await refreshUsers();

    onClose();
  };

  return (
    <VStack
      display={isOpen ? "flex" : "none"}
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      justifyContent="center"
      alignItems="center"
      bgColor="rgba(0, 0, 0, 0.5)"
      zIndex="9999"
    >
      <FormControl
        ref={modalRef}
        bgColor={modalBackgroundColor}
        w="600px"
        borderRadius="md"
        boxShadow="md"
        p={0}
        minWidth="400px"
        onSubmit={handleEditUser}
      >
        <Flex
          bgColor={modalHeaderBorderColor}
          justify="center"
          fontSize="xl"
          borderTopRadius="md"
          py={4}
          mb={4}
          fontWeight="medium"
        >
          Edit User
        </Flex>
        <Box pb={12} px={12}>
          <VStack w="full" spacing="16px">
            <InputLabel w="full">
              Name
              <SmallInputText
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoFocus
                mt="8px"
              />
            </InputLabel>
            <InputLabel w="full">
              Email
              <SmallInputText
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                mt="8px"
              />
            </InputLabel>
            <InputLabel w="full">
              User Role
              <SmallSelectInput
                value={role}
                onChange={(e: any) => setRole(e.target.value)}
                mt="8px"
              >
                <option value="VIEW_ONLY">View Only</option>
                <option value="ADMIN">Admin</option>
              </SmallSelectInput>
            </InputLabel>
          </VStack>
          <Box mt={4}>
            <SmallButton disabled={!canSubmit} mt={4} onClick={handleEditUser}>
              Update User
            </SmallButton>
            <SmallButton
              color={noBackgroundButtonTextColor}
              bgColor="none"
              mt={4}
              ml={4}
              onClick={onClose}
            >
              Cancel
            </SmallButton>
          </Box>
        </Box>
      </FormControl>
    </VStack>
  );
};

export default EditUserModal;
