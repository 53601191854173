import { ColorMode } from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";

interface StackedBarConfigsProps {
  theme: ColorMode;
  categories: string[];
  chartId: string;
  name: string;
  tickAmount?: number;
}

enum ChartTheme {
  LIGHT_TEXT_COLOR = "rgba(30, 48, 71, 0.6)",
  DARK_TEXT_COLOR = "rgba(214, 232, 255, 0.6)",
  LIGHT_GRID_LINE_COLOR = "#EAEAEB",
  DARK_GRID_LINE_COLOR = " #254166",
  LIGHT_LEGEND_TEXT_COLOR = "#1E3047",
  DARK_LEGEND_TEXT_COLOR = " #D6E8FF",
}

export const stackedBarConfigs = ({
  theme,
  categories,
  chartId,
  name,
  tickAmount
}: StackedBarConfigsProps): ApexOptions => ({
  colors: ["#3A5696", "#EC008C", "#00AEEF", "#E84E0F", "#FCBC00", "#2FB39D"],
  theme: {
    mode: theme,
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: "90%",
    },
  },
  chart: {
    type: "bar",
    stacked: true,
    background: theme === "dark" ? "#152130" : "#FFFFFF",
    toolbar: {
      show: false,
      export: {
        csv: {
          filename: name,
        },
      },
    },
    zoom: {
      enabled: false,
    },
    id: chartId,
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    borderColor:
      theme === "light"
        ? ChartTheme.LIGHT_GRID_LINE_COLOR
        : ChartTheme.DARK_GRID_LINE_COLOR,
    padding: {
      left: -5,
    },
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  stroke: {
    show: false,
  },
  xaxis: {
    labels: {
      style: {
        colors:
          theme === "light"
            ? ChartTheme.LIGHT_TEXT_COLOR
            : ChartTheme.DARK_TEXT_COLOR,
      },
    },
    categories,
  },
  yaxis: {
    tickAmount: tickAmount || 5,
    labels: {
      minWidth:100,
      maxWidth:250,
      style: {
        colors:
          theme === "light"
            ? ChartTheme.LIGHT_TEXT_COLOR
            : ChartTheme.DARK_TEXT_COLOR,
        cssClass: 'chart-data-labels',
      },
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    theme,
    shared: true,
    intersect: false,
  },
  legend: {
    markers: {
      radius: 100,
    },
    position: "bottom",
    horizontalAlign: "center",
    labels: {
      colors:
        theme === "light"
          ? ChartTheme.LIGHT_LEGEND_TEXT_COLOR
          : ChartTheme.DARK_LEGEND_TEXT_COLOR,
    },
  },

});
