import { Flex, Tag, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import FilterItem from "../../../@types/interfaces/app/filter-item.interface";

const CategoryItem: React.FC<FilterItem> = ({
  propertyName,
  chartName,
  filterCategory,
}) => {
  const categoryFilterBreadcrumbTextColor = useColorModeValue(
    "light.categoryFilterBreadcrumbTextColor",
    "dark.categoryFilterBreadcrumbTextColor"
  );

  const categoryFilterBreadcrumbBgColor = useColorModeValue(
    "light.categoryFilterBreadcrumbBgColor",
    "dark.categoryFilterBreadcrumbBgColor"
  );

  return (
    <Flex
      cursor="pointer"
      w="full"
      align="center"
      px="12px"
      py="4px"
      _hover={{
        bgColor: categoryFilterBreadcrumbBgColor,
      }}
    >
      <Flex flex="1" flexDir="column">
        <Text
          color={categoryFilterBreadcrumbTextColor}
          fontSize="10px"
          maxW="130px"
        >
          {chartName.split('`').join('')}
        </Text>
        <Text mt="3px" fontSize="13px">
          {propertyName}
        </Text>
      </Flex>

    </Flex>
  );
};

export default CategoryItem;
