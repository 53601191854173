/**
 * @param {Date | string | null} date
 * @returns {string} formatted date string
 * @example
 * formatDateDisplay(new Date()) // "January 1, 2021 at 12:00 AM"
 *
 * This function is designed to work with unknown date formats in UTC time.
 * It will attempt to parse the date string and return a formatted local date string.
 */

export const formatDateDisplay = (date: Date | string | null): string => {
  if (!date) return "";
  try {
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "UTC",
    };

    if (typeof date === "string") {
      const dtStr = date.slice(0, 10) + "T" + date.slice(11, 19) + "Z";
      const dateTime = new Date(dtStr).toLocaleDateString("en-US", dateOptions);
      if (dateTime === "Invalid Date") {
        const justDate = new Date(date).toLocaleDateString(
          "en-US",
          dateOptions
        );
        const justTime = new Date(date).toLocaleTimeString(
          "en-US",
          timeOptions
        );
        if (justDate === "Invalid Date") return date;

        return [justDate, justTime].join(" at ");
      }

      return dateTime;
    }

    const dt = date.toLocaleDateString("en-US", dateOptions);
    const tm = date.toLocaleTimeString("en-US", timeOptions);

    return [dt, tm].join(" at ");
  } catch {
    return "";
  }
};

export const checkValidEmail = (e: string): boolean => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(e).toLowerCase());
};
