import { Flex } from "@chakra-ui/react";
import React from "react";
import ComponentProps from "../../@types/interfaces/app/component-props.interface";
import Header from "../header/Header";

const NormalLayout: React.FC<ComponentProps> = ({ children }) => {
  return (
    <Flex pos="relative" flexDir="column" w="full">
      <Header />
      {children}
    </Flex>
  );
};

export default NormalLayout;
