import React, { useState, useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import RegularButton from "../components/buttons/RegularButton";
import CustomTable from "../components/tables/CustomTable";
import PageTitle from "../components/global/PageTitle";
import NormalLayout from "../components/layouts/NormalLayout";
import UserRoleTableItem from "../components/tables/UserRoleTableItem";
import { Role, User, UserRole } from "../utils/api-data-types";
import useApi from "../hooks/useApi";

export const ALLOW_NEW_ROLE_CREATION = false;

const UserRoles: React.FC = () => {
  const { fetchUsers } = useApi();
  const [users, setUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  useEffect(() => {
    (async () => {
      setRoles(["View Only", "Admin"]);

      const usersList = await fetchUsers();
      setUsers(usersList);
    })();
  }, []);

  return (
    <>
      <NormalLayout>
        <Flex w="full" flexDir="column" px="24px" pb="28px">
          <Flex
            align="center"
            mt="16px"
            justify="space-between"
            w="full"
            as="header"
          >
            <PageTitle>User Roles</PageTitle>
            {ALLOW_NEW_ROLE_CREATION && (
              <RegularButton flex="1" maxW="220px">
                Add New Role
              </RegularButton>
            )}
          </Flex>
          <CustomTable
            headers={[
              "Name",
              // "Creation Date",
              "Users Assigned",
            ]
              .concat([ALLOW_NEW_ROLE_CREATION ? "Actions" : ""])
              .filter((item) => item)}
          >
            {roles.map((role: Role) => (
              <UserRoleTableItem
                key={role}
                role={role}
                users={users.filter(
                  (user: User) => UserRole[user.role] === role
                )}
              />
            ))}
          </CustomTable>
        </Flex>
      </NormalLayout>
    </>
  );
};

export default UserRoles;
