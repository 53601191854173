import { Input, InputProps } from "@chakra-ui/react";
import React from "react";

const SmallInputText: React.FC<InputProps> = (props) => {
  return (
    <Input
      h="33px"
      fontSize="16px"
      borderRadius="4px"
      focusBorderColor="global.primary"
      border="1px solid #C2C8D0"
      px="8px"
      {...props}
    />
  );
};

export default SmallInputText;
