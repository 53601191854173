import { Text, TextProps } from "@chakra-ui/react";
import React from "react";

const InputLabel: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text as="label" fontSize="14px" {...rest}>
      {children}
    </Text>
  );
};

export default InputLabel;
