import { Td, TableCellProps, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const VHTableRowItem: React.FC<TableCellProps> = ({ children, ...rest }) => {
  const verticalAndHorizontalTableBorderColor = useColorModeValue(
    "light.verticalAndHorizontalTableBorderColor",
    "dark.verticalAndHorizontalTableBorderColor"
  );

  return (
    <Td
      fontSize="14px"
      borderWidth="1px"
      borderColor={verticalAndHorizontalTableBorderColor}
      h="60px"
      textAlign="center"
      {...rest}
    >
      {children}
    </Td>
  );
};

export default VHTableRowItem;
