import { extendTheme, type ThemeConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const theme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: true,
  styles: {
    global: (props: any) => ({
      html: {
        fontSize: ["16px", "16px", "16px", "16px", "18px", "18px"], //phone, tablet, desktop, desktop, ultrawide, TV
      },
      body: {
        bg: mode("#F5F7FA", "#101924")(props),
        color: mode("#1E3047", "#D6E8FF")(props),
      },
    }),
  },
  colors: {
    global: {
      primary: "#EC008C",
      continueButtonColor: "#1877F2",
      textInputPlaceholderColor: "#858585",
      linkTextColor: "#1C89B6",
      dividerColor: "#C2C8D0",
      primaryScheme: {
        100: "#EC008C",
        200: "#EC008C",
        300: "#EC008C",
        400: "#EC008C",
        500: "#EC008C",
        600: "#EC008C",
        700: "#EC008C",
        800: "#EC008C",
        900: "#EC008C",
      },
    },
    dark: {
      headerLinkColor: "rgba(214, 232, 255, 0.6)",
      headerNotificationIconColor: "#8998AC",
      cardsBackground: "#152130",
      cardsBorderColor: "#243145",
      menuCardBackground: "#203147",
      menuCardTitleColor: "#617389",
      menuLateralBorderColor: "#243145",
      headerProfileMenuIconColor: "#617389",
      headerProfileMenuTextColor: "rgba(214, 232, 255, 0.8)",
      headerProfileMenuNameColor: "#D6E8FF",
      // headerProfileMenuEmailColor: "rgba(214, 232, 255, 0.6)",
      paginationTabActionButtonColor: "rgba(214, 232, 255, 0.6)",
      filterIconColor: "#617389",
      filterTextColor: "#D6E8FF",
      filterPlaceholderColor: "rgba(214, 232, 255, 0.6)",
      selectedExportAsValue: "rgba(214, 232, 255, 0.6)",
      infoBoxTitleColor: "rgba(214, 232, 255, 0.6)",
      infoBoxDotsColor: "rgba(214, 232, 255, 0.3)",
      customTableHeaderBackground: "#1A293C",
      customTableHeaderBorderBottom: "#254166",
      customTableBackground: "#1E3047",
      customTableItemTextColor: "#D6E8FF",
      customTableRowScheme: {
        100: "#1A293C",
        200: "#1A293C",
        300: "#1A293C",
        400: "#1A293C",
        500: "#1A293C",
        600: "#1A293C",
        700: "#1A293C",
        800: "#1A293C",
        900: "#1A293C",
      },
      modalBackgroundColor: "#1A293C",
      modalHeaderBorderColor: "#254166",
      noBackgroundButtonTextColor: "#FFFFFF",
      verticalAndHorizontalTableBackgroundColor: "rgba(26, 41, 60, 0.6)",
      verticalAndHorizontalTableBorderColor: "#203147",
      backButtonTextColor: "rgba(214, 232, 255, 0.6)",
      categoryFilterBreadcrumbTextColor: "rgba(214, 232, 255, 0.4)",
      selectedCategoryBg: "#203147",
      selectedCategoryText: "#000",
      noDataFoundText: "rgba(137, 152, 172, 0.2)",
      categoryFilterBreadcrumbBgColor: "rgba(27, 41, 59, 0.7)",
    },
    light: {
      headerLinkColor: "rgba(30, 48, 71, 0.6)",
      headerNotificationIconColor: "#788391",
      cardsBackground: "#FFFFFF",
      menuCardTitleColor: "#A5ACB5",
      cardsBorderColor: "#FFFFFF",
      menuCardBackground: "#FFFFFF",
      headerProfileMenuIconColor: "#2e2e2e",
      headerProfileMenuTextColor: "#181818",
      menuLateralBorderColor: "#f1f1f1",
      headerProfileMenuNameColor: "#1E3047",
      // headerProfileMenuEmailColor: "rgba(30, 48, 71, 0.6)",
      paginationTabActionButtonColor: "rgba(30, 48, 71, 0.6)",
      filterIconColor: "#A5ACB5",
      filterTextColor: "#1E3047",
      filterPlaceholderColor: "rgba(30, 48, 71, 0.6)",
      selectedExportAsValue: "rgba(30, 48, 71, 0.6)",
      infoBoxTitleColor: "#7694BA",
      infoBoxDotsColor: "rgba(30, 48, 71, 0.3)",
      customTableHeaderBackground: "#F1F4F8",
      customTableBackground: "#F1F4F8",
      customTableHeaderBorderBottom: "rgba(37, 65, 102, 0.1)",
      customTableItemTextColor: "#1E3047",
      customTableRowScheme: {
        100: "#FFFFFF",
        200: "#FFFFFF",
        300: "#FFFFFF",
        400: "#FFFFFF",
        500: "#FFFFFF",
        600: "#FFFFFF",
        700: "#FFFFFF",
        800: "#FFFFFF",
        900: "#FFFFFF",
      },
      selectedCategoryBg: "rgba(240, 240, 240, 0.3)",
      selectedCategoryText: "#D6E8FF",
      noDataFoundText: "rgba(0, 0, 0, 0.3)",
      categoryFilterBreadcrumbBgColor: "rgba(238, 238, 238, 1)",
    },
    modalBackgroundColor: "#FFFFFF",
    modalHeaderBorderColor: "rgba(247, 251, 255, 0.1)",
    noBackgroundButtonTextColor: "#1E3047",
    verticalAndHorizontalTableBackgroundColor: "#F8F9F9",
    verticalAndHorizontalTableBorderColor: "#EAEDF0",
    backButtonTextColor: "#2e2e2e",
    categoryFilterBreadcrumbTextColor: "rgba(255, 255, 255, 0.4)",
  },
  fonts: {
    heading: "Montserrat",
    body: "Montserrat",
  },
  breakpoints: {
    sm: "480px",
    md: "768px",
    lg: "992px",
    xl: "1280px",
    "2xl": "1536px",
  },
  components: {
    Button: {
      variants: {
        solid: (props: any) => ({
          bg: "none",
          _hover: {
            bg: "none",
          },
          _active: {
            bg: "none",
            boxShadow: "none",
          },
          _focus: {
            boxShadow: "none",
          },
        }),
      },
    },
  },
} as ThemeConfig);
