import { TableCellProps, Td, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const VHTableRowHeader: React.FC<TableCellProps> = ({ children, ...rest }) => {
  const verticalAndHorizontalTableBackgroundColor = useColorModeValue(
    "light.verticalAndHorizontalTableBackgroundColor",
    "dark.verticalAndHorizontalTableBackgroundColor"
  );

  const verticalAndHorizontalTableBorderColor = useColorModeValue(
    "light.verticalAndHorizontalTableBorderColor",
    "dark.verticalAndHorizontalTableBorderColor"
  );

  return (
    <Td
      scope="row"
      textAlign="center"
      h="58px"
      borderWidth="1px"
      borderColor={verticalAndHorizontalTableBorderColor}
      bgColor={verticalAndHorizontalTableBackgroundColor}
      fontSize="14px"
      {...rest}
    >
      {children}
    </Td>
  );
};

export default VHTableRowHeader;
