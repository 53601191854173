export type User = {
  id: string;
  name: string;
  email: string;
  group: string;
  created_at: Date | string;
  role: "ADMIN" | "VIEW_ONLY";
  require_pw_reset: 0 | 1 | null;
  last_login_date: Date | string | null;
  hashed_password?: "HIDDEN" | null;
};

export type Role = "View Only" | "Admin";

export enum UserRole {
  ADMIN = "Admin",
  VIEW_ONLY = "View Only",
}
