import { Button, ButtonProps, Icon, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { IoMdArrowDropleft } from "react-icons/io";

const BackButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  const backButtonTextColor = useColorModeValue(
    "light.backButtonTextColor",
    "dark.backButtonTextColor"
  );

  return (
    <Button
      h="57px"
      aria-label="back"
      bg="none"
      p="0"
      variant="solid"
      color={backButtonTextColor}
      lineHeight="17px"
      fontSize="14px"
      _hover={{}}
      _active={{}}
      leftIcon={<Icon as={IoMdArrowDropleft} fontSize="17px" />}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default BackButton;
