import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

const RegularButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button
      h="50px"
      bgColor="global.primary"
      boxShadow="0px 1px 2px rgba(0, 0, 0, 0.05)"
      variant="solid"
      borderRadius="4px"
      color="white"
      fontWeight="600"
      lineHeight="24px"
      fontSize="14px"
      _hover={{}}
      _active={{}}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default RegularButton;
