import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

const FilledButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button
      h="50px"
      bgColor="global.continueButtonColor"
      boxShadow="0px 1px 2px rgba(0, 0, 0, 0.05)"
      variant="solid"
      borderRadius="4px"
      color="white"
      fontWeight="500"
      fontFamily="Inter"
      lineHeight="24px"
      fontSize="16px"
      _hover={{}}
      _active={{}}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default FilledButton;
