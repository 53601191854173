import {
  Table,
  TableContainer,
  Tbody,
  TableContainerProps,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

interface VerticalAndHorizontalTableProps extends TableContainerProps {
  headers: string[];
}

const VerticalAndHorizontalTable: React.FC<VerticalAndHorizontalTableProps> = ({
  children,
  headers,
  ...rest
}) => {
  const headerData = ["", ...headers];

  const verticalAndHorizontalTableBackgroundColor = useColorModeValue(
    "light.verticalAndHorizontalTableBackgroundColor",
    "dark.verticalAndHorizontalTableBackgroundColor"
  );

  const verticalAndHorizontalTableBorderColor = useColorModeValue(
    "light.verticalAndHorizontalTableBorderColor",
    "dark.verticalAndHorizontalTableBorderColor"
  );

  return (
    <TableContainer overflowY="auto" w="full" {...rest}>
      <Table variant="unstyled">
        <Thead>
          <Tr>
            {headerData.map((d, i) => {
              if (i === 0) {
                return <Th h="28px" key={d}></Th>;
              }

              return (
                <Th
                  borderColor={verticalAndHorizontalTableBorderColor}
                  bgColor={verticalAndHorizontalTableBackgroundColor}
                  borderTopWidth="1px"
                  borderBottomWidth="1px"
                  borderLeftWidth={i === 1 ? "1px" : undefined}
                  borderRightWidth={
                    i === headerData.length - 1 ? "1px solid " : undefined
                  }
                  borderTopLeftRadius={i === 1 ? "4px" : undefined}
                  borderTopRightRadius={
                    i === headerData.length - 1 ? "4px" : undefined
                  }
                  px="32px"
                  textAlign="center"
                  h="28px"
                  textTransform="capitalize"
                  fontWeight="normal"
                  key={d}
                >
                  {d}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  );
};

export default VerticalAndHorizontalTable;
