import { FiTag, FiUsers } from "react-icons/fi";
import { DashboardStructure } from "../@types/interfaces/app/dashboard-structure";


var structure : DashboardStructure[] = []
const view = localStorage.getItem("userView")
if(view!='leadership') {
  structure = [
    {
      name: "Programs",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "events_completed"
              "events_planned"
              "events_canceled"
              "event_attendees"
              "chart0"
              "chart2"
            `,
            null,
            `
              "events_completed events_planned events_canceled event_attendees"
              'chart0 chart0 chart0 chart0'
              'chart2 chart2 chart2 chart2'
            `,
            `
              "events_completed events_planned events_canceled event_attendees"
              'chart0 chart0 chart0 chart0'
              'chart2 chart2 chart2 chart2'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#86ff4d",
            },
            {
              name: "Programs in Planning",
              KPIKey: "events_planned",
              icon: FiTag,
              color: "#1877f2",
            },
            {
              name: "Cancelled Programs",
              KPIKey: "events_canceled",
              icon: FiTag,
              color: "#ff0000",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#86ff4d",
            },
          ],
          charts: [
            {
              name: "Event Status Over Time",
              dataKey: "events_by_status_over_time",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chart0"
            },
            {
              name: "Event Status Over Service Type",
              dataKey: "events_by_status_over_service",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chart2",
            },
          ],
        },
        {
          name: "Page 2",
          templateArea: [
            `
              "events_completed"
              "events_planned"
              "events_canceled"
              "event_attendees"
              "chart01"
              "chart1"
              "chart02"
              "chart3"
            `,
            null,
            `
              "events_completed events_planned events_canceled event_attendees"
              'chart01 chart01 chart1 chart1'
              'chart02 chart02 chart3 chart3'
            `,
            `
              "events_completed events_planned events_canceled event_attendees"
              'chart01 chart01 chart1 chart1'
              'chart02 chart02 chart3 chart3'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#86ff4d",
            },
            {
              name: "Programs in Planning",
              KPIKey: "events_planned",
              icon: FiTag,
              color: "#1877f2",
            },
            {
              name: "Cancelled Programs",
              KPIKey: "events_canceled",
              icon: FiTag,
              color: "#ff0000",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#86ff4d",
            },
          ],
          charts: [
            {
              name: "Event by Topic",
              dataKey: "events_by_status_over_topic",
              hasCategories: true,
              chartType: "stakedBar",
              hasLabels: false,
              chartId: "chart01",
            },
            {
              name: "Event Status",
              dataKey: "events_by_status",
              hasCategories: false,
              chartType: "donutChart",
              hasLabels: true,
              chartId: "chart1",
            },
            {
              name: "Event by Type",
              dataKey: "events_by_status_over_type",
              hasCategories: true,
              chartType: "stakedBar",
              hasLabels: false,
              chartId: "chart02",
            },
            {
              name: "Cancellation Rationale",
              dataKey: "events_by_cancellation_rationale",
              hasCategories: true,
              chartType: "stakedBar",
              hasLabels: false,
              chartId: "chart3",
            },
          ],
        },
      ],
    },
    {
      name: "Speakers",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "chart31"
              "chart32"
            `,
            null,
            `
              'chart31 chart31 chart31 chart31'
              'chart32 chart32 chart32 chart32'
            `,
            `
            'chart31 chart31 chart31 chart31'
            'chart32 chart32 chart32 chart32'
            `,
          ],
          charts: [
            {
              name: "Event by Speaker",
              dataKey: "events_by_status_over_speaker",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chart31",
            },
            {
              name: "Honoraria Spend by Speaker",
              dataKey: "honoraria_by_speaker",
              hasCategories: true,
              chartType: "clusteredColumn",
              hasLabels: false,
              chartId: "chart32",
            },
          ],
        },
        {
          name: "Page 2",
          templateArea: [
            `
              "chartp31"
              "chartp32"
            `,
            null,
            `
              'chartp31 chartp31 chartp31 chartp31'
              'chartp32 chartp32 chartp32 chartp32'
            `,
            `
            'chartp31 chartp31 chartp31 chartp31'
            'chartp32 chartp32 chartp32 chartp32'
            `,
          ],
          charts: [
            {
              name: "Event by Pathology Speaker",
              dataKey: "p_events_by_status_over_speaker",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chartp31",
            },
            {
              name: "Honoraria Spend by Pathology Speaker",
              dataKey: "p_honoraria_by_speaker",
              hasCategories: true,
              chartType: "clusteredColumn",
              hasLabels: false,
              chartId: "chartp32",
            },
          ],
        },
      ],
    },
    {
      name: "Regions",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "events_completed"
              "events_planned"
              "events_canceled"
              "event_attendees"
              "chart21"
              "chart23"
            `,
            null,
            `
              "events_completed events_planned events_canceled event_attendees"
              'chart21 chart21 chart21 chart21'
              'chart23 chart23 chart23 chart23'
            `,
            ` 
              "events_completed events_planned events_canceled event_attendees"
              'chart21 chart21 chart21 chart21'
              'chart23 chart23 chart23 chart23'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#86ff4d",
            },
            {
              name: "Programs in Planning",
              KPIKey: "events_planned",
              icon: FiTag,
              color: "#1877f2",
            },
            {
              name: "Cancelled Programs",
              KPIKey: "events_canceled",
              icon: FiTag,
              color: "#ff0000",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#86ff4d",
            },
          ],
          charts: [
            {
              name: "Event Status Over Region",
              dataKey: "events_by_status_over_region",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chart21",
            },
            {
              name: "Event Status Over Territory",
              dataKey: "events_by_status_over_territory",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chart23",
            },
          ],
        },
      ],
    },
    {
      name: "Attendees",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "events_completed"
              "event_attendees"
              "chart5"
              "charta6"
              "charta7"
            `,
            null,
            `
              "events_completed events_completed event_attendees event_attendees"
              'chart5 chart5 chart5 chart5'
              'charta6 charta6 charta6 charta7'
            `,
            `
              "events_completed events_completed event_attendees event_attendees"
              'chart5 chart5 chart5 chart5'
              'charta6 charta6 charta6 charta7'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#86ff4d",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#1877f2",
            },
          ],
            charts: [
              {
                name: "Attendees Over Time",
                dataKey: "attendees_by_status_over_time",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "chart5",
              },
              {
                name: "Attendees by Credentials",
                dataKey: "attendees_by_credentials",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "charta6",
              },
  
              {
                name: "Attendees by Program Type",
                dataKey: "attendees_by_format",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "charta7",
              },
            ],
        },
        {
          name: "Page 2",
          templateArea: [
            `
              "events_completed"
              "event_attendees"
              "chart8"
              "chart7"
            `,
            null,
            `
              "events_completed events_completed event_attendees event_attendees"
              'chart8 chart8 chart8 chart8'
              'chart7 chart7 chart7 chart7'
            `,
            `
              "events_completed events_completed event_attendees event_attendees"
              'chart8 chart8 chart8 chart8'
              'chart7 chart7 chart7 chart7'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#86ff4d",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#1877f2",
            },
          ],
            charts: [
              {
                name: "Attendees by Territory",
                dataKey: "attendees_by_territory",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "chart7",
              },
              {
                name: "Attendees by Region",
                dataKey: "attendees_by_region",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "chart8",
              },
            ],
        },
        {
          name: "Page 3",
          templateArea: [
            `
              "events_completed"
              "event_attendees"
              "charta9"
              "chartet"
              "chartey"
            `,
            null,
            `
              "events_completed events_completed event_attendees event_attendees"
              'charta9 charta9 charta9 charta9'
              'chartet chartet chartey chartey'
            `,
            `
              "events_completed events_completed event_attendees event_attendees"
              'charta9 charta9 charta9 charta9'
              'chartet chartet chartey chartey'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#86ff4d",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#1877f2",
            },
          ],
            charts: [
              {
                name: "Attendees by Program Speaker",
                dataKey: "attendees_by_speaker",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "charta9",
              },
              {
                name: "Attendees by Program Type",
                dataKey: "attendees_by_type",
                hasCategories: true,
                chartType: "stakedBar",
                hasLabels: false,
                chartId: "chartet",
              },
              {
                name: "Attendees by Topic",
                dataKey: "attendees_by_topic",
                hasCategories: true,
                chartType: "stakedBar",
                hasLabels: false,
                chartId: "chartey",
              },
            ],
        },
        {
          name: "Page 4",
          templateArea: [
            `
              "chartdgqr"
            `,
            null,
            `
              'chartdgqr chartdgqr chartdgqr chartdgqr'
              'chartdgqr chartdgqr chartdgqr chartdgqr'
            `,
            `
              'chartdgqr chartdgqr chartdgqr chartdgqr'
              'chartdgqr chartdgqr chartdgqr chartdgqr'
            `,
          ],
            charts: [
              {
                name: "QR Code Utilization",
                dataKey: "data_grid_qr",
                hasCategories: false,
                chartType: "dataGrid",
                hasLabels: false,
                chartId: "chartdgqr",
              },
            ],
        },
        {
          name: "Page 5",
          templateArea: [
            `
              "chartdga"
            `,
            null,
            `
              'chartdga chartdga chartdga chartdga'
              'chartdga chartdga chartdga chartdga'
            `,
            `
              'chartdga chartdga chartdga chartdga'
              'chartdga chartdga chartdga chartdga'
            `,
          ],
            charts: [
              {
                name: "Data Table",
                dataKey: "data_grid_attendee",
                hasCategories: false,
                chartType: "dataGrid",
                hasLabels: false,
                chartId: "chartdga",
              },
            ],
        },
      ],
    },
    {
      name: "Data Table",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "chartdg"
            `,
            null,
            `
              'chartdg chartdg chartdg chartdg'
              'chartdg chartdg chartdg chartdg'
            `,
            `
              'chartdg chartdg chartdg chartdg'
              'chartdg chartdg chartdg chartdg'
            `,
          ],
          charts: [
            {
              name: "",
              dataKey: "data_grid",
              hasCategories: false,
              chartType: "dataGrid",
              hasLabels: false,
              chartId: "chartdg",
            },
          ],
        },
      ],
    },
  ];
} else {
  structure  = [
    {
      name: "Programs",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "events_completed"
              "events_planned"
              "events_canceled"
              "event_attendees"
              "chart0"
              "chart2"
            `,
            null,
            `
              "events_completed events_planned events_canceled event_attendees"
              'chart0 chart0 chart0 chart0'
              'chart2 chart2 chart2 chart2'
            `,
            `
              "events_completed events_planned events_canceled event_attendees"
              'chart0 chart0 chart0 chart0'
              'chart2 chart2 chart2 chart2'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#86ff4d",
            },
            {
              name: "Programs in Planning",
              KPIKey: "events_planned",
              icon: FiTag,
              color: "#1877f2",
            },
            {
              name: "Cancelled Programs",
              KPIKey: "events_canceled",
              icon: FiTag,
              color: "#ff0000",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#86ff4d",
            },
          ],
          charts: [
            {
              name: "Event Status Over Time",
              dataKey: "events_by_status_over_time",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chart0"
            },
            {
              name: "Event Status Over Service Type",
              dataKey: "events_by_status_over_service",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chart2",
            },
          ],
        },
        {
          name: "Page 2",
          templateArea: [
            `
              "events_completed"
              "events_planned"
              "events_canceled"
              "event_attendees"
              "chart01"
              "chart1"
              "chart02"
              "chart3"
            `,
            null,
            `
              "events_completed events_planned events_canceled event_attendees"
              'chart01 chart01 chart1 chart1'
              'chart02 chart02 chart3 chart3'
            `,
            `
              "events_completed events_planned events_canceled event_attendees"
              'chart01 chart01 chart1 chart1'
              'chart02 chart02 chart3 chart3'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#86ff4d",
            },
            {
              name: "Programs in Planning",
              KPIKey: "events_planned",
              icon: FiTag,
              color: "#1877f2",
            },
            {
              name: "Cancelled Programs",
              KPIKey: "events_canceled",
              icon: FiTag,
              color: "#ff0000",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#86ff4d",
            },
          ],
          charts: [
            {
              name: "Event by Topic",
              dataKey: "events_by_status_over_topic",
              hasCategories: true,
              chartType: "stakedBar",
              hasLabels: false,
              chartId: "chart01",
            },
            {
              name: "Event Status",
              dataKey: "events_by_status",
              hasCategories: false,
              chartType: "donutChart",
              hasLabels: true,
              chartId: "chart1",
            },
            {
              name: "Event by Type",
              dataKey: "events_by_status_over_type",
              hasCategories: true,
              chartType: "stakedBar",
              hasLabels: false,
              chartId: "chart02",
            },
            {
              name: "Cancellation Rationale",
              dataKey: "events_by_cancellation_rationale",
              hasCategories: true,
              chartType: "stakedBar",
              hasLabels: false,
              chartId: "chart3",
            },
          ],
        },
      ],
    },
    {
      name: "Speakers",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "chart31"
              "chart31"
            `,
            null,
            `
              'chart31 chart31 chart31 chart31'
              'chart31 chart31 chart31 chart31'
            `,
            `
            'chart31 chart31 chart31 chart31'
            'chart31 chart31 chart31 chart31'
            `,
          ],
          charts: [
            {
              name: "Event by Speaker",
              dataKey: "events_by_status_over_speaker",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chart31",
            },
          ],
        },
      ],
    },
    {
      name: "Regions",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "events_completed"
              "events_planned"
              "events_canceled"
              "event_attendees"
              "chart21"
              "chart23"
            `,
            null,
            `
              "events_completed events_planned events_canceled event_attendees"
              'chart21 chart21 chart21 chart21'
              'chart23 chart23 chart23 chart23'
            `,
            ` 
              "events_completed events_planned events_canceled event_attendees"
              'chart21 chart21 chart21 chart21'
              'chart23 chart23 chart23 chart23'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#86ff4d",
            },
            {
              name: "Programs in Planning",
              KPIKey: "events_planned",
              icon: FiTag,
              color: "#1877f2",
            },
            {
              name: "Cancelled Programs",
              KPIKey: "events_canceled",
              icon: FiTag,
              color: "#ff0000",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#86ff4d",
            },
          ],
          charts: [
            {
              name: "Event Status Over Region",
              dataKey: "events_by_status_over_region",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chart21",
            },
            {
              name: "Event Status Over Territory",
              dataKey: "events_by_status_over_territory",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chart23",
            },
          ],
        },
      ],
    },
    {
      name: "Attendees",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "events_completed"
              "event_attendees"
              "chart5"
              "charta6"
              "charta7"
            `,
            null,
            `
              "events_completed events_completed event_attendees event_attendees"
              'chart5 chart5 chart5 chart5'
              'charta6 charta6 charta6 charta7'
            `,
            `
              "events_completed events_completed event_attendees event_attendees"
              'chart5 chart5 chart5 chart5'
              'charta6 charta6 charta6 charta7'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#86ff4d",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#1877f2",
            },
          ],
            charts: [
              {
                name: "Attendees Over Time",
                dataKey: "attendees_by_status_over_time",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "chart5",
              },
              {
                name: "Attendees by Credentials",
                dataKey: "attendees_by_credentials",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "charta6",
              },
  
              {
                name: "Attendees by Program Type",
                dataKey: "attendees_by_format",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "charta7",
              },
            ],
        },
        {
          name: "Page 2",
          templateArea: [
            `
              "events_completed"
              "event_attendees"
              "chart8"
              "chart7"
            `,
            null,
            `
              "events_completed events_completed event_attendees event_attendees"
              'chart8 chart8 chart8 chart8'
              'chart7 chart7 chart7 chart7'
            `,
            `
              "events_completed events_completed event_attendees event_attendees"
              'chart8 chart8 chart8 chart8'
              'chart7 chart7 chart7 chart7'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#86ff4d",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#1877f2",
            },
          ],
            charts: [
              {
                name: "Attendees by Territory",
                dataKey: "attendees_by_territory",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "chart7",
              },
              {
                name: "Attendees by Region",
                dataKey: "attendees_by_region",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "chart8",
              },
            ],
        },
        {
          name: "Page 3",
          templateArea: [
            `
              "events_completed"
              "event_attendees"
              "charta9"
              "chartet"
              "chartey"
            `,
            null,
            `
              "events_completed events_completed event_attendees event_attendees"
              'charta9 charta9 charta9 charta9'
              'chartet chartet chartey chartey'
            `,
            `
              "events_completed events_completed event_attendees event_attendees"
              'charta9 charta9 charta9 charta9'
              'chartet chartet chartey chartey'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#86ff4d",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#1877f2",
            },
          ],
            charts: [
              {
                name: "Attendees by Program Speaker",
                dataKey: "attendees_by_speaker",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "charta9",
              },
              {
                name: "Attendees by Program Type",
                dataKey: "attendees_by_type",
                hasCategories: true,
                chartType: "stakedBar",
                hasLabels: false,
                chartId: "chartet",
              },
              {
                name: "Attendees by Topic",
                dataKey: "attendees_by_topic",
                hasCategories: true,
                chartType: "stakedBar",
                hasLabels: false,
                chartId: "chartey",
              },
            ],
        },
        {
          name: "Page 4",
          templateArea: [
            `
              "chartdgqr"
            `,
            null,
            `
              'chartdgqr chartdgqr chartdgqr chartdgqr'
              'chartdgqr chartdgqr chartdgqr chartdgqr'
            `,
            `
              'chartdgqr chartdgqr chartdgqr chartdgqr'
              'chartdgqr chartdgqr chartdgqr chartdgqr'
            `,
          ],
            charts: [
              {
                name: "QR Code Utilization",
                dataKey: "data_grid_qr",
                hasCategories: false,
                chartType: "dataGrid",
                hasLabels: false,
                chartId: "chartdgqr",
              },
            ],
        },
        {
          name: "Page 5",
          templateArea: [
            `
              "chartdga"
            `,
            null,
            `
              'chartdga chartdga chartdga chartdga'
              'chartdga chartdga chartdga chartdga'
            `,
            `
              'chartdga chartdga chartdga chartdga'
              'chartdga chartdga chartdga chartdga'
            `,
          ],
            charts: [
              {
                name: "Data Table",
                dataKey: "data_grid_attendee",
                hasCategories: false,
                chartType: "dataGrid",
                hasLabels: false,
                chartId: "chartdga",
              },
            ],
        },
      ],
    },
    {
      name: "Data Table",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "chartdg"
            `,
            null,
            `
              'chartdg chartdg chartdg chartdg'
              'chartdg chartdg chartdg chartdg'
            `,
            `
              'chartdg chartdg chartdg chartdg'
              'chartdg chartdg chartdg chartdg'
            `,
          ],
          charts: [
            {
              name: "",
              dataKey: "data_grid",
              hasCategories: false,
              chartType: "dataGrid",
              hasLabels: false,
              chartId: "chartdg",
            },
          ],
        },
      ],
    },
  ];
}


export const exampleStructure: DashboardStructure[] = structure ;
